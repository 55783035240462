<template>
  <div class="satisfaction-records">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="满意度登记记录" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 工单名称 -->
        <ml-input
          prop="titleLike"
          placeholder="请输入工单名称"
          style="margin-bottom: 0"
          label="工单名称:"
          :input-style="{ width: '120px' }"
          v-model="searchData.titleLike"
        />
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
          @change="selectUnit"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          @change="searchFn"
        />
        <!-- 日期 -->
        <ml-date-picker
          prop="date"
          startPlaceholder="开始日期"
          endPlaceholder="结束日期"
          label="评价时间:"
          type="datetimerange"
          v-model="searchData.date"
          :style="{ 'margin-bottom': 0, 'margin-right': '40px' }"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip class="default-tip" style="margin-bottom: 0; border: 0">
      <!-- 新增按钮 -->
      <template #title>
        <div class="tip-header">
          <el-button-group>
            <el-button
              :type="searchData.tabbarStatus === 0 ? 'primary' : 'default'"
              @click="searchData.tabbarStatus = 0"
            >
              工单评价
            </el-button>
            <el-button
              :type="searchData.tabbarStatus === 1 ? 'primary' : 'default'"
              @click="searchData.tabbarStatus = 1"
            >
              月度评价
            </el-button>
          </el-button-group>
          <div>
            <el-button
              icon="el-icon-download"
              type="primary"
              @click="clickExport"
              v-if="searchData.tabbarStatus === 1"
            >
              导出
            </el-button>
            <el-button
              icon="el-icon-plus"
              type="primary"
              @click="clickAdd"
              v-if="searchData.tabbarStatus === 1"
            >
              添加月度评价
            </el-button>
          </div>
        </div>
      </template>
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(417)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="20%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="项目名称"
          prop="projectName"
          min-width="20%"
        />
        <!-- <el-table-column align="center" label="时间" prop="createTime" min-width="15%" /> -->
        <el-table-column
          align="center"
          label="年月"
          prop="createTime"
          min-width="15%"
          v-if="searchData.tabbarStatus === 1"
        >
          <template #default="{ row }">
            {{ row.year }}-{{ Number(row.month) > 10 ? row.month : `0${row.month}` }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="searchData.tabbarStatus === 0"
          align="center"
          label="标题"
          prop="title"
          min-width="10%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 0"
          align="center"
          label="描述"
          prop="content"
          min-width="10%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 0"
          align="center"
          label="评分"
          prop="score"
          min-width="10%"
        />
        <!-- 混入表2 -->
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="工程师服务态度"
          prop="attitude"
          min-width="15%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="工程师仪表仪态"
          prop="appearance"
          min-width="15%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="工程师技术能力"
          prop="capability"
          min-width="15%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="服务操作规范性"
          prop="normative"
          min-width="15%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="响应及时性"
          prop="timeliness"
          min-width="15%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="故障处理效果"
          prop="quality"
          min-width="15%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="配件齐全"
          prop="completely"
          min-width="10%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="服务单等资料提交情况"
          prop="holistic"
          min-width="10%"
        />
        <el-table-column
          v-if="searchData.tabbarStatus === 1"
          align="center"
          label="总体平均分"
          prop="average"
          min-width="10%"
        />

        <!-- 混入表2 -->
        <el-table-column align="center" label="操作" priop="orderId" min-width="30%">
          <template #default="scope">
            <el-button
              v-if="searchData.tabbarStatus === 0"
              size="mini"
              icon="el-icon-unlock"
              type="primary"
              @click="clickView(scope.$index, scope.row)"
            >
              查看满意度调查工单
            </el-button>
            <!-- 表2 -->
            <el-button
              v-if="searchData.tabbarStatus === 1"
              size="mini"
              icon="el-icon-unlock"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="searchData.tabbarStatus === 1"
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除提示框 -->
    <ml-dialog ref="deleteDlgRef" content="确定删除吗？" @click-submit="deleteDlgSubmit" />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  formetData,
  clearMemoryPageFn,
  setMemoryPageFn,
  getUrlParamsString,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { BASEURL, prefix } from '@API'

export default {
  name: 'SatisfactionRecords',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    const toKen = computed(() => getters.getAccessToken)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      tabbarStatus: getMemoryPage.value.searchData.tabbarStatus || 0,
      titleLike: getMemoryPage.value.searchData.titleLike || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || '',
      date: getMemoryPage.value.searchData.date || []
    })
    // tab状态
    // const tabbarStatus = ref(0)
    watch(
      () => searchData.tabbarStatus,
      () => {
        tabberData.page = 1
        getLogTabberData()
      }
    )
    // 单位配置
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }

    const selectUnit = () => {
      searchData.projectId = ''
      searchFn()
    }
    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          getProjectData(newvalue)
        }
      }
    )
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData, { date: [], tabbarStatus: 0 })
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取列表
    const getLogTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date', 'tabbarStatus'])
      if (searchData.date.length > 0) {
        searchDataParams.createTimeStart = formetData(searchData.date[0])
        searchDataParams.createTimeEnd = formetData(searchData.date[1])
      }
      searchDataParams.titleLike = replacePerCent(searchData.titleLike)
      const paramsName =
        searchData.tabbarStatus === 0 ? 'fetchGetSatisfactionRecords' : 'fetchGetMonthEvaluates'
      const { data, total } = await getTabberData(dispatch, paramsName, params, searchDataParams)
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getLogTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 添加
    const clickAdd = () => {
      clearMemoryPageFn(commit, searchData, { date: [], tabbarStatus: 0 })
      router.push({ path: '/satisfactionRecordsAction' })
    }
    // 添加
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/satisfactionRecordsAction', query: { evaluateId: row.evaluateId } })
    }
    const clickView = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/faultServiceCenterDetails', query: { errorId: row.errorId } })
    }
    // 删除
    const deleteDlgRef = ref()
    const deleteDlgData = reactive({
      row: {}
    })
    const clickDelete = row => {
      deleteDlgData.row = row
      deleteDlgRef.value.showDialog = true
    }
    const deleteDlgSubmit = () => {
      const { evaluateId } = deleteDlgData.row
      dispatch('fetchmonthEvaluateDelete', evaluateId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          deleteDlgRef.value.showDialog = false
          getLogTabberData()
        }
      })
    }
    // 导出
    const clickExport = () => {
      const searchDataParams = searchParams(searchData, ['date', 'tabbarStatus'])
      const paramsUrl = getUrlParamsString(searchDataParams)
      if (searchData.date.length > 0) {
        searchDataParams.createTimeStart = formetData(searchData.date[0])
        searchDataParams.createTimeEnd = formetData(searchData.date[1])
      }
      const url = paramsUrl
        ? `/v1/op/monthEvaluateExport?${paramsUrl}&token=${
            toKen.value
          }&access_token=${encodeURIComponent(toKen.value)}`
        : `/v1/op/monthEvaluateExport?token=${toKen.value}&access_token=${encodeURIComponent(
            toKen.value
          )}`
      window.location.href = `${BASEURL}${prefix}${url}`
    }
    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      getLogTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      unitOptions,
      projectData,
      selectUnit,
      clickAdd,
      clickUpdate,
      clickView,
      clickDelete,
      deleteDlgRef,
      deleteDlgSubmit,
      clickExport
    }
  }
}
</script>

<style lang="scss" scoped>
.satisfaction-records {
  @extend %params-global;
}
</style>
